import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { invariant } from '@epic-web/invariant'
import { json, type LoaderFunctionArgs, type ActionFunctionArgs, type MetaFunction, redirect } from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Spacer } from '#app/components/spacer.tsx'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { twoFAVerificationType } from '#app/routes/settings+/profile.two-factor.tsx'
import { getUserId, login, requireAnonymous, sessionKey } from '#app/utils/auth.server.ts'
import { validateCSRF } from '#app/utils/csrf.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { combineResponseInits, useIsPending } from '#app/utils/misc.tsx'
import { get } from '#app/utils/refundr.server.ts'
import { authSessionStorage } from '#app/utils/session.server.ts'
import { redirectWithToast } from '#app/utils/toast.server.ts'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { verifySessionStorage } from '#app/utils/verification.server.ts'
import { getRedirectToUrl, type VerifyFunctionArgs } from './verify.tsx'
import { type Customer } from '#app/model/response.ts'
import { Button } from '#app/components/ui/button.tsx'
import {useEffect, useLayoutEffect, useState} from 'react';
import _ from 'lodash';

const verifiedTimeKey = 'verified-time'
const unverifiedSessionIdKey = 'unverified-session-id'
const rememberKey = 'remember'

export async function handleNewSession(
	{
		request,
		session,
		redirectTo,
		remember,
		requireOnboardingRedirect = false,
	}: {
		request: Request
		session: { userId: string; id: string; expirationDate: Date }
		redirectTo?: string
		remember: boolean
		requireOnboardingRedirect: boolean
	},
	responseInit?: ResponseInit,
) {
	const verification = await prisma.verification.findUnique({
		select: { id: true },
		where: {
			target_type: { target: session.userId, type: twoFAVerificationType },
		},
	})
	const userHasTwoFactor = Boolean(verification)

	if (userHasTwoFactor) {
		const verifySession = await verifySessionStorage.getSession()
		verifySession.set(unverifiedSessionIdKey, session.id)
		verifySession.set(rememberKey, remember)
		const redirectUrl = getRedirectToUrl({
			request,
			type: twoFAVerificationType,
			target: session.userId,
			redirectTo,
		})
		return redirect(
			`${redirectUrl.pathname}?${redirectUrl.searchParams}`,
			combineResponseInits(
				{
					headers: {
						'set-cookie': await verifySessionStorage.commitSession(verifySession),
					},
				},
				responseInit,
			),
		)
	} else {
		const authSession = await authSessionStorage.getSession(request.headers.get('cookie'))
		authSession.set(sessionKey, session.id)

		return redirect(
			safeRedirect(requireOnboardingRedirect ? '/onboarding' : '/returns'),
			combineResponseInits(
				{
					headers: {
						'set-cookie': await authSessionStorage.commitSession(authSession, {
							expires: remember ? session.expirationDate : undefined,
						}),
					},
				},
				responseInit,
			),
		)
	}
}

export async function handleVerification({ request, submission }: VerifyFunctionArgs) {
	invariant(submission.value, 'Submission should have a value by this point')
	const authSession = await authSessionStorage.getSession(request.headers.get('cookie'))
	const verifySession = await verifySessionStorage.getSession(request.headers.get('cookie'))

	const remember = verifySession.get(rememberKey)
	const { redirectTo } = submission.value
	const headers = new Headers()
	authSession.set(verifiedTimeKey, Date.now())

	const unverifiedSessionId = verifySession.get(unverifiedSessionIdKey)
	if (unverifiedSessionId) {
		const session = await prisma.session.findUnique({
			select: { expirationDate: true },
			where: { id: unverifiedSessionId },
		})
		if (!session) {
			throw await redirectWithToast('/login', {
				type: 'error',
				title: 'Invalid session',
				description: 'Could not find session to verify. Please try again.',
			})
		}
		authSession.set(sessionKey, unverifiedSessionId)

		headers.append(
			'set-cookie',
			await authSessionStorage.commitSession(authSession, {
				expires: remember ? session.expirationDate : undefined,
			}),
		)
	} else {
		headers.append('set-cookie', await authSessionStorage.commitSession(authSession))
	}

	headers.append('set-cookie', await verifySessionStorage.destroySession(verifySession))

	return redirect(safeRedirect(redirectTo), { headers })
}

export async function shouldRequestTwoFA(request: Request) {
	const authSession = await authSessionStorage.getSession(request.headers.get('cookie'))
	const verifySession = await verifySessionStorage.getSession(request.headers.get('cookie'))
	if (verifySession.has(unverifiedSessionIdKey)) return true
	const userId = await getUserId(request)
	if (!userId) return false
	// if it's over two hours since they last verified, we should request 2FA again
	const userHasTwoFA = await prisma.verification.findUnique({
		select: { id: true },
		where: { target_type: { target: userId, type: twoFAVerificationType } },
	})
	if (!userHasTwoFA) return false
	const verifiedTime = authSession.get(verifiedTimeKey) ?? new Date(0)
	const twoHours = 1000 * 60 * 2
	return Date.now() - verifiedTime > twoHours
}

const LoginFormSchema = z.object({
	username: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})



const togglePassword = () => {
	// @ts-ignore
	const passwordElement: HTMLFormElement = document.getElementById('login-form-password')
	// @ts-ignore
	const button: HTMLButtonElement = document.getElementById('password-button')
	if (passwordElement.type === 'password') {
		passwordElement.type = 'text'
		button.textContent = 'Hide'
	} else {
		passwordElement.type = 'password'
		button.textContent = 'Show'
	}
}


export async function action({ request }: ActionFunctionArgs) {
	// Get user id from session if it exists else redirect to home page
	await requireAnonymous(request)
	const formData = await request.formData()
	// csrf stand for Cross-Site Request Forgery. This involves saving and verifying a token saved in browser cookie
	await validateCSRF(formData, request.headers)
	// Honeypot is a simple technique to prevent spam bots from submitting forms. It works by adding a hidden field to the form that bots will fill, but humans won't.
	checkHoneypot(formData)

	const submission = await parse(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== 'submit') return { ...data, session: null, user: null }
				const response = await login(data.username, data.password)
				if (!response) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid username or password',
					})
					return z.NEVER
				}
				const { session, user } = response
				return { ...data, session, user }
			}),
		async: true,
	})
	// get the password off the payload that's sent back
	delete submission.payload.password

	if (submission.intent !== 'submit') {
		// @ts-expect-error - conform should probably have support for doing this
		delete submission.value?.password
		return json({ status: 'idle', submission } as const)
	}
	if (!submission.value?.session) {
		return json({ status: 'error', submission } as const, { status: 400 })
	}

	const { session, remember, redirectTo, user } = submission.value

	// Fetching a customer here just to make sure that any previously authenticated customer from mobile has their refundr customer id
	// saved into the user database. All subsequent fetches from the session are done with the refundr-web-app user id.

	// IF REFUNDR_CUSTOMER_ID is found then we can assume that this new customer has been onboarded
	let requireOnboardingRedirect = false
	if (!user?.refundrCustomerId) {
		const customer = await get<Customer>(request, user?.cognitoIdToken, '/customer/cognito')
		if (customer) {
			// @ts-ignore
			await prisma.user.update({
				where: { id: user.id },
				data: {
					refundrCustomerId: customer.id,
				},
			})
		} else {
			requireOnboardingRedirect = true
		}
	}

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
		requireOnboardingRedirect: requireOnboardingRedirect,
	})
}



export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const agentFriendlyParam = searchParams.get('friendlyId')
	useEffect(() => {
		const agent_friendly_id = window.localStorage.getItem("agent_friendly_id");
		if (_.isEmpty(agent_friendly_id)) {
			window.localStorage.setItem("agent_friendly_id", agentFriendlyParam ?? '');
		}
	}, [agentFriendlyParam])

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getFieldsetConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastSubmission: actionData?.submission,
		onValidate({ formData }) {
			return parse(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div className="flex min-h-full flex-col justify-center pb-32 pt-10">
			<div className="mx-auto w-full max-w-md">
				<div className="flex flex-col gap-3 text-center">
					<h2 className="text-h2">Welcome back!</h2>
					<p className="text-body-md text-muted-foreground">Please enter your credentials.</p>
				</div>
				<Spacer size="xs" />

				<div>
					<div className="mx-auto w-full max-w-md px-4">
						<Form method="POST" {...form.props}>
							<AuthenticityTokenInput />
							<HoneypotInputs />
							<Field
								labelProps={{ children: 'email' }}
								inputProps={{
									...conform.input(fields.username),
									autoFocus: true,
									className: 'lowercase',
									autoComplete: 'username',
								}}
								errors={fields.username.errors}
							/>

							<div className="flex gap-1 items-center">
								<div className="w-full">
									<Field
										className="w-full"
										labelProps={{ children: 'password' }}
										inputProps={{
											...conform.input(fields.password, {
												type: 'password',
											}),
											autoComplete: 'current-password',
										}}
										errors={fields.password.errors}
									/>
								</div>
								<div>
									<Button type="button" id="password-button" className="relative -top-1" size="sm" onClick={() => togglePassword()}>
										Show
									</Button>
								</div>
							</div>

							<div className="flex justify-between">
								<CheckboxField
									labelProps={{
										htmlFor: fields.remember.id,
										children: 'remember me',
									}}
									buttonProps={conform.input(fields.remember, {
										type: 'checkbox',
									})}
									errors={fields.remember.errors}
								/>
								<div>
									<Link to="/forgot-password" className="text-body-xs font-semibold">
										forgot password?
									</Link>
								</div>
							</div>

							<input {...conform.input(fields.redirectTo, { type: 'hidden' })} />
							<ErrorList errors={form.errors} id={form.errorId} />

							<div className="flex items-center justify-between gap-6 pt-3">
								<StatusButton
									className="w-full"
									status={isPending ? 'pending' : actionData?.status ?? 'idle'}
									type="submit"
									disabled={isPending}
								>
									LOG IN
								</StatusButton>
							</div>
						</Form>
						<div className="flex items-center justify-center gap-2 pt-6">
							<span className="text-muted-foreground">New here?</span>
							<Link to={redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : '/signup'}>create an account</Link>
						</div>
						<div className="flex items-center justify-center gap-2 pt-6 text-center text-gray-400">
							Note: these are the same credentials used on our iPhone app. No need to create another account.
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'login to refundr' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
